import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; 
import { Project } from '../../models/project.model';

import 'rxjs/add/observable/of';

@Injectable()
export class ProjectsService{
  public projects: Project[];

  public setProjects(projects: Project[]): void {
    this.projects = projects;
  }

  public getProjects(): Observable<Project[]> {
    return Observable.of(this.projects);
  }

  public clearProjects(): void {
    this.projects = null;
  }

  public updateProject(id: number, data: any): void {
    if ( this.projects ) {
      this.projects = this.projects.map(project => {
        if(project.id === id){
          project = {...project, ...data};
        }
        return project;
      });
    }
  }
}