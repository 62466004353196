import {HttpClient} from '@angular/common/http';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { API_SERVER } from './constants.config';

export const AUTH_CONFIG = {
  baseEndpoint: API_SERVER.protocol + '://' + API_SERVER.ip + API_SERVER.startpoint,
  login: {
    alwaysFail: false,
    rememberMe: true,
    endpoint: '/users/auth',
    method: 'post',
    redirect: {
      success: '/user/dashboard',
      failure: null,
    },
    defaultErrors: ['Email o contraseña son incorrectos, inténtalo de nuevo.'],
    defaultMessages: ['Has ingresado a Sinecta.'],
  },
  logout: {
    alwaysFail: true,
    endpoint: '/users/logout',
    method: 'get',
    redirect: {
      success: 'auth',
      failure: '/',
    },
    defaultErrors: ['Algo salió mal, inténtalo de nuevo.'],
    defaultMessages: ['Has salido de Sinecta.'],
  },
  requestPass: {
    endpoint: '/users/request-pass',
    method: 'post',
    redirect: {
      success: '/',
      failure: null,
    },
    defaultErrors: ['Algo salió mal, inténtalo de nuevo.'],
    defaultMessages: ['Las instrucciones para recuperar tu contraseña han sido enviadas a tu correo.'],
  },
  resetPass: {
    endpoint: '/users/reset-pass',
    method: 'post',
    redirect: {
      success: '/',
      failure: null,
    },
    resetPasswordTokenKey: 'reset_password_token',
    defaultErrors: ['Algo salió mal, inténtalo de nuevo.'],
    defaultMessages: ['Tu contraseña se ha cambiado exitosamente.'],
  },
};
