import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';

export class Policy extends RESTModel {
    public id: number;
    public Claims: any;
    public Subdivisions: any;
    public asegurado: string;
    public beneficiary: string;
    public cobertura: any;
    public costPerHectare: number;
    public dateEndPolicy: any;
    public dateStartPolicy: any;
    public impPag: any;
    public impTotal: any;
    public persona: string;
    public plan: any;
    public policyArea: number;
    public policyNum: string;
    public sucEmitida: any;
    public sumAsegurada: any;
    public tecnicoEmi: any;
    public tipoSuelo: any;

    constructor(service: RESTService<Policy>, data: any) {
        super(service, data);
    }
}