import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/of';

import { RESTService } from '../../services/rest.service';
import { AnyFn } from '@ngrx/store/src/selector';

@Injectable()
export class AppCheckService extends RESTService<any> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.getValue()) {
                    const JWTToken  = new NbAuthJWTToken(token.getValue());
                    //JWTToken.setValue(token.getValue());
                    const tokenPayload = JWTToken.getPayload();
                    this.currentUserId = tokenPayload.id;
                }
            });
    }

    // POST
    public CheckApp(
        data: any,
    ): Promise<any> {

        this.setApiEndpoint(
            '/userproject/appcheck/user/' +
            this.currentUserId
        );

        return this.save(data);
    }

    // POST
    public RemoveApp(
        data: any,
    ): Promise<any> {

        this.setApiEndpoint(
            '/userproject/appcheck/user/' +
            this.currentUserId +
            '/remove'
        );

        return this.save(data);
    }


    public newResource(data: any): any {
        return this, data;
    }
}
