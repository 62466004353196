import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/of';

import { RESTService } from '../../services/rest.service';

// Models
import { Sucursal } from '../../models/sucursal.model';

@Injectable()
export class DespachoService extends RESTService<Sucursal> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.getValue()) {
                    const JWTToken  = new NbAuthJWTToken(token.getValue());
                    //JWTToken.setValue(token.getValue());

                    const tokenPayload = JWTToken.getPayload();
                    this.currentUserId = tokenPayload.id;
                }
            });
    }

    // GET /Sucursales

    public getAllDespachos(): Promise<Sucursal[]> {
        this.setApiEndpoint(
            '/despachos/'
        );
        return this.getAll();
    }

    public newResource(data: any): Sucursal {
        return new Sucursal(this, data);
    }
}
