import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';

export class Users extends RESTModel {
    public idcompany: number;
    public iduser: number;
    public ismanager: boolean;
    public error: any;
    public isActive: any;
    public sucursal: string;
    public sucursalid: number;
    public createdAt: string;
    public updatedAt: string;

    constructor(service: RESTService<Users>, data: any) {
        super(service, data);
    }
}

