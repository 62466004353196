import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';
import { Project } from './project.model';

export class User extends RESTModel {
  public id: number;
  public fullName: string;
  public position: string;
  public email: string;
  public profileImg: string;
  public isAdmin: boolean;
  public isActive: boolean;
  public password: string;
  public Companies: any;
  public sucursal: any;

  public projects: Project[];

  constructor(service: RESTService<User>, data: any) {
    super(service, data);
  }
}

