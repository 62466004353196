import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/of';

import { RESTService } from '../../services/rest.service';

// Models
import { Action } from '../../models/action.model';

@Injectable()
export class ActionService extends RESTService<Action> {

  private tokenPayload: any;
  private currentUserId: number;

  constructor(http: HttpClient, authService: NbAuthService) {
    super(http, authService);
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.getValue()) {
          const JWTToken  = new NbAuthJWTToken(token.getValue());
          //JWTToken.setValue(token.getValue());
          const tokenPayload = JWTToken.getPayload();
          this.currentUserId = tokenPayload.id;
        }
      });
  }

  // GET /videos
  public getActionsById(
    userId = this.currentUserId,
  ): Promise<Action[]> {

    this.setApiEndpoint(
      '/users/' +
      userId +
      '/actions/'
    );
    return this.getAll();
  }

  // POST /videos
  public createAction(
    data: any
  ): Promise<Action> {

    this.setApiEndpoint(
      '/actions/create'
    );

    return this.save(data);
  }


  // GET videos/{videoId}
  public getActions(): Promise<Action[]> {

    this.setApiEndpoint(
      '/actions'
    );

    return this.getAll();
  }


  public newResource(data: any): Action {
    return new Action(this, data);
  }
}
