import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';

export class Group extends RESTModel {
    public id: number;
    public userid: number;
    public name: any;
    public policyNum: any;
    public cliente: any;
    public Measurements: any;

    constructor(service: RESTService<Group>, data: any) {
        super(service, data);
    }
}