import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/user.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StateService } from '../../../@core/data/state.service';

import { User } from '../../../models/user.model';
import { from } from 'rxjs/observable/from';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public client = window.location.host.split('.')[0];
  public dev = isDevMode();
  public v2 = isDevMode();

  @Input() position = 'normal';
  @Input() toggler;

  user: User;
  nickName: any = '';
  userMenu: any;
  is_manager = false;
  ver_logos = false;
  public bancaUrl: boolean = false;
  public dashboardShow: boolean = false;

  currentLang = 'es';
  logo: string;

  constructor(
    private sidebarService: NbSidebarService,
    private _userService: UserService,
    public router: Router,
    protected translate: TranslateService,
    private http: HttpClient,
    public stateService: StateService

  ) {
    try {
      if(window.location.pathname === '/exp/') {
        this.dev = true;
      }else{
        this.dev = false;
      }
      if (this.translate.currentLang === 'es' || typeof this.translate.currentLang === 'undefined') {
        moment.locale('es');
        this.userMenu = [{
          title: this.translate.instant('general.ENGLISH'),
        }, {
          title: this.translate.instant('general.LOGOUT'),
          link: '/auth/logout',
        }];
      } else {
        moment.locale('en');
        this.userMenu = [{
          title: this.translate.instant('general.SPANISH'),
        }, {
          title: this.translate.instant('general.LOGOUT'),
          link: '/auth/logout',
        }];
      }
  
      this.translate.onLangChange.subscribe((event: any) => {
        this.currentLang = event.lang;
        if (event.lang === 'es') {
          moment.locale('es');
          this.userMenu = [{
            title: this.translate.instant('general.ENGLISH'),
          }, {
            title: this.translate.instant('general.LOGOUT'),
            link: '/auth/logout',
          }];
        } else {
          moment.locale('en');
          this.userMenu = [{
            title: this.translate.instant('general.SPANISH'),
          }, {
            title: this.translate.instant('general.LOGOUT'),
            link: '/auth/logout',
          }];
        }
      });
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const options = { headers: headers };
  
      const url = window.location.hostname;
      if (url === 'clientes.sinecta.com' || url == 'localhost' || url === 'agro-asemex.sinecta.com' || url === 'agroasemex.sinecta.com') {
          this.bancaUrl = true;
          this.ver_logos = true;
          this.logo = "https://server.sinecta.com/assets/images/clientes/Sinecta_Logotipo-2-06.png"
      } else {
        this.http
          .get('https://server.sinecta.com/api/v1/domains', options)
          .subscribe(
            (result: any) => {
              // const full = window.location.host;
              // const full = 'agroasemex.sinecta.com';
              // const parts = full.split('.');
              // const sub = parts[0];
              // const domain = parts[1];
              // if (sub && sub.includes('clientes') || sub.includes('localhost') ) {
              //     return this.logo = `/assets/images/gds_blue.png`;
              // }
  
              // if ( sub.includes('159')) {
              //   return this.logo = `/assets/images/gds_blue.png`;
              // }
  
              // const subdomain = result.find(d => d['name'] === sub);
              // if (subdomain) {
                return this.logo = `https://server.sinecta.com/assets/images/clientes/Sinecta_Logotipo-2-06.png`;
              // }
              // return window.location.href = 'http://159.89.131.247/';
            });
      }
    } catch (error) {
      console.log(error);
    }
  }

  onMenuClick(event: any) {
    if (event.title === this.translate.instant('general.SPANISH')) {
      this.changeLanguage('es');
    } else if (event.title === this.translate.instant('general.ENGLISH')) {
      this.changeLanguage('en');
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    let nickName = this.user.fullName.split(' ');

    if ( nickName.length > 1 ) {
      this.nickName = nickName[0][0] + nickName[1][0];
    } else if ( nickName.length == 1 ) {
      this.nickName = nickName[0][0];
    }
    
    if (this.user.Companies && this.user.Companies[0] && this.user.Companies[0].Companyuser.ismanager === true) {
      this.is_manager = true;
    }

    // Determine if "Tablero" button is going to be shown
    this._userService.getInfoDashboards().then(information => {
      if ( information.length > 0 ) {
        information.forEach((dashboards: any) => {
          if( dashboards.users.includes(this.user.id) ) {
            this.dashboardShow = true;
          }
        });
      }
    });
  }

  changeLanguage(lang: string): void {
    this.translate.use(lang);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    if (this.user.isAdmin) {
      this.router.navigate(['admin']);
    } else {
      this.router.navigate(['user']);
    }
  }

  logOut() {
    this.router.navigate(['/auth/logout'])
  }

  toggleSinecta() {
    this.stateService.selectedView = !this.stateService.selectedView;
    this.stateService.mapHidden = this.stateService.selectedView && !this.stateService.mapHidden;
  }
}
