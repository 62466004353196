import { isDevMode } from '@angular/core'

// const ip = isDevMode() ? 'staging.sinecta.com' : 'server.sinecta.com'
const ip = 'server.sinecta.com'
// const ip = 'staging.sinecta.com'
// const ip = 'testing.sinecta.com'
// const ip = false ? '165.227.57.119:8081' : 'server.sinecta.com'
// const ip = 'testing.sinecta.com'

// const protocol = isDevMode() ? 'https' : 'https'
const protocol = 'https'
// const protocol = false ? 'http' : 'https'

export const API_SERVER = {
    protocol,
    ip,
    startpoint: '/api/v1',
};

export const GOOGLE_MAPS = {
    apiKey: 'AIzaSyDTNI3y1P6OWTmPWZkuuA-rjr5diya4Dyw',
};
